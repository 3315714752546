/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Služby"}>
        <SiteHeader />

        <Column className="css-1wzdrt2 --style2 --full" name={"uvod"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/134/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1300}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s3 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"3"}>
              
              <Title className="title-box fs--72" style={{"maxWidth":1276}} content={"<span style='color: white'>Nabídka služeb</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"obsah"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Nadpis"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Nadpis"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"maxWidth":1080}} columns={"2"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Nadpis"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--left mt--0 pt--0">
              
              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, změnit velikost řádkování, zarovnat text na stranu a dokonce nastavit maximální šířku textu v pixlech."}>
              </Text>

              <Subtitle className="subtitle-box" content={"179,— Kč"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"o-nas"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--36" style={{"maxWidth":383}} content={"<span style=\"color: var(--color-dominant);\">Tomáš Kamenovský</span><br>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">+420 797 811 2X0<br>info@vase-stranky.com<br>IČ: 12345678</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":495}} content={"<span style=\"color: var(--color-dominant);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">Vytvořeno přes <a style=\"color: inherit\" href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}